@import "src/themes/daikiri/styles/index.scss";
.picker {
  position: relative;
}

.swatch {
  border: 1px solid getvar($colors, "neutral", "200");
  width: space(2);
  height: space(2);
  border-radius: space(0.5);
  cursor: pointer;
  margin-left: space();
}

.popover {
  position: absolute;
  bottom: calc(100% + 6px);
  right: 0;
  border-radius: space(0.5);
  z-index: 1;

  @include md {
    left: 0;
  }
}
